const DB_NAME = 'AideaDB';
const STORE_NAME = 'notes';
const DB_VERSION = 1;

let db = null;

const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error("IndexedDB error:", event.target.error);
      reject("IndexedDB error");
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      const objectStore = db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      objectStore.createIndex('date', 'date', { unique: false });
    };
  });
};

export const addNote = (note) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.add({ ...note, date: new Date() });

    request.onerror = (event) => {
      console.error("Error adding note:", event.target.error);
      reject("Error adding note");
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

export const getAllNotes = () => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll();

    request.onerror = (event) => {
      console.error("Error fetching notes:", event.target.error);
      reject("Error fetching notes");
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

export const updateNote = (id, updatedNote) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.put({ ...updatedNote, id });

    request.onerror = (event) => {
      console.error("Error updating note:", event.target.error);
      reject("Error updating note");
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

export const deleteNote = (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(id);

    request.onerror = (event) => {
      console.error("Error deleting note:", event.target.error);
      reject("Error deleting note");
    };

    request.onsuccess = (event) => {
      resolve();
    };
  });
};

export const getNoteById = (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(id);

    request.onerror = (event) => {
      console.error("Error fetching note:", event.target.error);
      reject("Error fetching note");
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

// DB 초기화
initDB().then(() => {
  console.log("IndexedDB initialized successfully");
}).catch((error) => {
  console.error("Failed to initialize IndexedDB:", error);
});

// 명명된 객체로 내보내기
const dbService = {
  addNote,
  getAllNotes,
  updateNote,
  deleteNote,
  getNoteById
};

export default dbService;