import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Box, Checkbox, FormControlLabel } from '@mui/material';
import { getAllNotes } from '../services/db';

function IdeaGeneratorPage() {
  const [notes, setNotes] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [topic, setTopic] = useState('');
  const [generatedIdea, setGeneratedIdea] = useState('');

  useEffect(() => {
    loadNotes();
  }, []);

  const loadNotes = async () => {
    try {
      const allNotes = await getAllNotes();
      setNotes(allNotes);
    } catch (error) {
      console.error('Failed to load notes:', error);
    }
  };

  const handleNoteSelection = (noteId) => {
    setSelectedNotes(prev => 
      prev.includes(noteId) 
        ? prev.filter(id => id !== noteId)
        : [...prev, noteId]
    );
  };

  const handleGenerateIdea = async () => {
    try {
      const selectedNoteContents = notes
        .filter(note => selectedNotes.includes(note.id))
        .map(note => note.content);
  
      const response = await fetch('/api/generate-idea', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic, notes: selectedNoteContents }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate idea');
      }
  
      const data = await response.json();
      setGeneratedIdea(data.idea);
    } catch (error) {
      console.error('Error generating idea:', error);
      setGeneratedIdea('아이디어 생성에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        아이디어 생성기
      </Typography>
      <TextField
        fullWidth
        label="주제"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        margin="normal"
      />
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>메모 선택:</Typography>
      {notes.map(note => (
        <FormControlLabel
          key={note.id}
          control={
            <Checkbox
              checked={selectedNotes.includes(note.id)}
              onChange={() => handleNoteSelection(note.id)}
            />
          }
          label={note.title}
        />
      ))}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleGenerateIdea}
        sx={{ mt: 2 }}
      >
        아이디어 생성
      </Button>
      {generatedIdea && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">생성된 아이디어:</Typography>
          <Typography>{generatedIdea}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default IdeaGeneratorPage;