import React from 'react';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';
import { useLongPress } from 'use-long-press';

function NoteCard({ id, title, content, date, color, isPinned, isSelected, onSelect, onLongPress, isSelectionMode }) {
  const bind = useLongPress(() => {
    if (!isSelectionMode) {
      onLongPress(id);
    }
  }, {
    onCancel: () => {
      if (isSelectionMode) {
        onSelect(id);
      }
    },
    threshold: 500,
  });

  const handleClick = (e) => {
    e.preventDefault();
    if (isSelectionMode) {
      onSelect(id);
    } else {
      // 상세 보기 페이지로 이동하는 로직
      onSelect(id);
    }
  };

  return (
    <Card 
      {...bind()}
      onClick={handleClick}
      sx={{ 
        cursor: 'pointer',
        backgroundColor: color || 'inherit',
        border: isSelected ? '2px solid #1976d2' : 'none',
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div">
          {isPinned && '📌 '}{title}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          {new Date(date).toLocaleDateString()}
        </Typography>
        <Typography variant="body2">
          {content.length > 100 ? `${content.substring(0, 100)}...` : content}
        </Typography>
      </CardContent>
      {!isSelectionMode && (
        <CardActions>
          <Button size="small" onClick={(e) => { e.stopPropagation(); /* 상세 보기 로직 */ }}>상세보기</Button>
        </CardActions>
      )}
    </Card>
  );
}

export default NoteCard;