import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NoteList from '../components/NoteList';
import SelectionToolbar from '../components/SelectionToolbar';
import { getAllNotes, deleteNote, updateNote } from '../services/db';

function MainPage() {
  const [notes, setNotes] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadNotes();
  }, []);

  const loadNotes = async () => {
    try {
      const allNotes = await getAllNotes();
      setNotes(allNotes);
    } catch (error) {
      console.error('Failed to load notes:', error);
    }
  };

  const handleNewNote = () => {
    navigate('/new');
  };

  const handleDeleteNotes = async () => {
    try {
      await Promise.all(selectedNotes.map(id => deleteNote(id)));
      setSelectedNotes([]);
      setIsSelectionMode(false);
      loadNotes();
    } catch (error) {
      console.error('Failed to delete notes:', error);
    }
  };

  const handlePinNotes = async () => {
    try {
      await Promise.all(selectedNotes.map(id => 
        updateNote(id, { ...notes.find(note => note.id === id), isPinned: true })
      ));
      setSelectedNotes([]);
      setIsSelectionMode(false);
      loadNotes();
    } catch (error) {
      console.error('Failed to pin notes:', error);
    }
  };

  const handleChangeColor = async (color) => {
    try {
      await Promise.all(selectedNotes.map(id => 
        updateNote(id, { ...notes.find(note => note.id === id), color: color })
      ));
      setSelectedNotes([]);
      setIsSelectionMode(false);
      loadNotes();
    } catch (error) {
      console.error('Failed to change note color:', error);
    }
  };

  const handleLongPress = (id) => {
    if (!isSelectionMode) {
      setIsSelectionMode(true);
      setSelectedNotes([id]);
    } else {
      handleNoteSelect(id);
    }
  };

  const handleNoteSelect = (id) => {
    if (isSelectionMode) {
      setSelectedNotes(prev => 
        prev.includes(id) ? prev.filter(noteId => noteId !== id) : [...prev, id]
      );
    } else {
      navigate(`/note/${id}`);
    }
  };

  const handleCancelSelection = () => {
    setIsSelectionMode(false);
    setSelectedNotes([]);
  };

  return (
    <Box sx={{ mt: 4, pb: 10, position: 'relative', minHeight: '100vh' }}>
      {isSelectionMode && (
        <SelectionToolbar
          onDelete={handleDeleteNotes}
          onPin={handlePinNotes}
          onChangeColor={handleChangeColor}
          onCancel={handleCancelSelection}
        />
      )}
      <NoteList 
        notes={notes} 
        selectedNotes={selectedNotes}
        onNoteSelect={handleNoteSelect}
        onLongPress={handleLongPress}
        isSelectionMode={isSelectionMode}
      />
      {!isSelectionMode && (
        <Fab 
          color="primary" 
          aria-label="add" 
          onClick={handleNewNote}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  );
}

export default MainPage;