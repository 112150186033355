import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextField, Button, Box, Fab, keyframes } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { styled } from '@mui/system';

const pulse = keyframes`
  0% {
    transform: translate(-50%, 0) scale(1);
  }
  50% {
    transform: translate(-50%, 0) scale(1.1);
  }
  100% {
    transform: translate(-50%, 0) scale(1);
  }
`;

const AnimatedFab = styled(Fab)(({ theme, isListening, isActive }) => ({
  position: 'fixed',
  bottom: theme?.spacing?.(4) || '32px',
  left: '50%',
  transform: 'translateX(-50%)',
  animation: isActive ? `${pulse} 0.5s ease-in-out infinite` : 'none',
  backgroundColor: isListening 
    ? theme?.palette?.secondary?.main || '#f50057'
    : theme?.palette?.primary?.main || '#3f51b5',
  '&:hover': {
    backgroundColor: isListening 
      ? theme?.palette?.secondary?.dark || '#c51162'
      : theme?.palette?.primary?.dark || '#303f9f',
  },
  marginLeft: 0,
}));

function NoteEditor({ note, onSave }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (note) {
      setTitle(note.title || '');
      setContent(note.content || '');
    }
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [note]);

  const handleSave = () => {
    onSave({ title, content });
  };

  const startListening = useCallback(() => {
    if (recognitionRef.current && !recognitionRef.current.running) {
      recognitionRef.current.start();
    }
  }, []);

  const stopListening = useCallback(() => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsListening(false);
  }, []);

  const handleVoiceInput = useCallback(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    
    if (!recognitionRef.current) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = 'ko-KR';

      recognitionRef.current.onresult = (event) => {
        const finalTranscript = event.results[event.results.length - 1][0].transcript;
        setContent(prev => prev + finalTranscript + ' ');
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(startListening, 1000);
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        if (event.error === 'no-speech') {
          startListening();
        }
      };

      recognitionRef.current.onend = () => {
        if (isListening) {
          startListening();
        }
      };
    }

    if (isListening) {
      stopListening();
    } else {
      setIsListening(true);
      startListening();
    }
  }, [isListening, startListening, stopListening]);

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ pb: 10 }}>
      <TextField
        fullWidth
        label="제목"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="내용"
        multiline
        rows={4}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
        저장
      </Button>
      <AnimatedFab
        color={isListening ? "secondary" : "primary"}
        onClick={handleVoiceInput}
        aria-label="음성 입력"
        isListening={isListening}
        isActive={isListening}
      >
        <MicIcon />
      </AnimatedFab>
    </Box>
  );
}

export default NoteEditor;