import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Popover, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import PaletteIcon from '@mui/icons-material/Palette';
import CloseIcon from '@mui/icons-material/Close';

const colors = [
  '#FFFFFF', '#F28B82', '#FBBC04', '#FFF475', '#CCFF90', 
  '#A7FFEB', '#CBF0F8', '#AECBFA', '#D7AEFB', '#FDCFE8', 
  '#E6C9A8', '#E8EAED', '#FAD000', '#FF9800', '#039BE5', '#7CB342'
];

function SelectionToolbar({ onDelete, onPin, onChangeColor, onCancel }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleColorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (color) => {
    onChangeColor(color);
    handleColorClose();
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(false);
    onDelete();
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <>
      <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar sx={{ justifyContent: 'space-around' }}>
          <IconButton edge="start" color="inherit" onClick={onPin} sx={{ fontSize: '2rem' }}>
            <PushPinIcon fontSize="inherit" />
          </IconButton>
          <IconButton color="inherit" onClick={handleDeleteClick} sx={{ fontSize: '2rem' }}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
          <IconButton color="inherit" onClick={handleColorClick} sx={{ fontSize: '2rem' }}>
            <PaletteIcon fontSize="inherit" />
          </IconButton>
          <IconButton edge="end" color="inherit" onClick={onCancel} sx={{ fontSize: '2rem' }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleColorClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Grid container spacing={1} sx={{ p: 1 }}>
          {colors.map((color) => (
            <Grid item key={color}>
              <IconButton
                sx={{
                  width: 36,
                  height: 36,
                  backgroundColor: color,
                  '&:hover': {
                    backgroundColor: color,
                  },
                }}
                onClick={() => handleColorSelect(color)}
              />
            </Grid>
          ))}
        </Grid>
      </Popover>
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"메모 삭제 확인"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            선택한 메모를 정말로 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>취소</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectionToolbar;