// src/pages/NoteDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';
import { getNoteById } from '../services/db';

function NoteDetailPage() {
  const [note, setNote] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadNote = async () => {
      try {
        const loadedNote = await getNoteById(parseInt(id));
        setNote(loadedNote);
      } catch (error) {
        console.error('Failed to load note:', error);
      }
    };
    loadNote();
  }, [id]);

  if (!note) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>{note.title}</Typography>
      <Typography variant="subtitle1" gutterBottom>
        작성일: {new Date(note.date).toLocaleString()}
      </Typography>
      <Typography variant="body1" paragraph>{note.content}</Typography>
      <Button variant="contained" onClick={() => navigate(`/edit/${id}`)}>수정</Button>
      <Button variant="outlined" onClick={() => navigate('/')} sx={{ ml: 2 }}>뒤로 가기</Button>
    </Box>
  );
}

export default NoteDetailPage;