import React from 'react';
import { Grid } from '@mui/material';
import NoteCard from './NoteCard';

function NoteList({ notes, selectedNotes, onNoteSelect, onLongPress, isSelectionMode }) {
  return (
    <Grid container spacing={2}>
      {notes.sort((a, b) => (b.isPinned ? 1 : 0) - (a.isPinned ? 1 : 0)).map((note) => (
        <Grid item xs={12} sm={6} md={4} key={note.id}>
          <NoteCard
            id={note.id}
            title={note.title}
            content={note.content}
            date={note.date}
            color={note.color}
            isPinned={note.isPinned}
            isSelected={selectedNotes.includes(note.id)}
            onSelect={() => onNoteSelect(note.id)}
            onLongPress={() => onLongPress(note.id)}
            isSelectionMode={isSelectionMode}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default NoteList;