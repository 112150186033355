import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Container } from '@mui/material';
import MainPage from './pages/MainPage';
import NoteEditorPage from './pages/NoteEditorPage';
import NoteDetailPage from './pages/NoteDetailPage';
import IdeaGeneratorPage from './pages/IdeaGeneratorPage';

const theme = createTheme(); // 기본 테마를 사용하거나 커스텀 테마를 정의할 수 있습니다.

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Container maxWidth="lg">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/new" element={<NoteEditorPage />} />
            <Route path="/edit/:id" element={<NoteEditorPage />} />
            <Route path="/note/:id" element={<NoteDetailPage />} />
            <Route path="/generate-idea" element={<IdeaGeneratorPage />} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;