import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import NoteEditor from '../components/NoteEditor';
import { addNote, updateNote, getNoteById } from '../services/db';

function NoteEditorPage() {
  const [note, setNote] = useState({ title: '', content: '' });
  const { id } = useParams();
  const navigate = useNavigate();

  const loadNote = useCallback(async (noteId) => {
    try {
      const loadedNote = await getNoteById(parseInt(noteId));
      if (loadedNote) {
        setNote(loadedNote);
      } else {
        console.error('Note not found');
        navigate('/');
      }
    } catch (error) {
      console.error('Failed to load note:', error);
      // 에러 처리 로직 (예: 사용자에게 알림)
    }
  }, [navigate]);

  useEffect(() => {
    if (id) {
      loadNote(id);
    }
  }, [id, loadNote]);

  const handleSave = async (updatedNote) => {
    try {
      if (id) {
        await updateNote(parseInt(id), updatedNote);
      } else {
        await addNote(updatedNote);
      }
      navigate('/');
    } catch (error) {
      console.error('Failed to save note:', error);
      // 에러 처리 로직 (예: 사용자에게 알림)
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {id ? '메모 수정' : '새 메모 작성'}
      </Typography>
      <NoteEditor note={note} onSave={handleSave} />
    </Box>
  );
}

export default NoteEditorPage;